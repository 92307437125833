import './style.scss'
import { Button, Checkbox, Input } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Api from '@/api/login'
import { UserStore } from '@/global-states'
import { LoginStore } from '../../states'

const PasswordLogin: FC = () => {
  const { phone, agreementChecked, rememberAccount } = LoginStore
  const [mobileError, setMobileError] = useState(false)
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setMobileError(phone.length > 11)
  }, [phone])

  const onMobileChange = (e: any) => {
    LoginStore.phone = e.target.value.replaceAll(/\D/g, '')
  }

  const onPasswordChange = (e: any) => {
    setPassword(e.target.value)
  }

  const onAgreeChange = (e: any) => {
    LoginStore.agreementChecked = e.target.checked
  }

  const switchLoginType = () => {
    LoginStore.loginType = 'mobile'
  }

  const onRememberAccountChange = (e: any) => {
    LoginStore.rememberAccount = e.target.checked
  }

  const onPressEnter = () => {
    if (agreementChecked && phone.length === 11 && password.length) {
      doLogin()
    }
  }

  // 登陆
  const doLogin = async () => {
    setLoading(true)
    try {
      const user = await Api.login({
        phone,
        password,
        type: 'PASSWORD'
      })
      if (!user?.token) {
        throw new Error('登录失败')
      }
      UserStore.setUserToken(user)
      const redirectUrl = sessionStorage.getItem('redirectUrl')
      const code = sessionStorage.getItem('code')
      if (code && redirectUrl) {
        sessionStorage.removeItem('redirectUrl')
        setTimeout(() => {
          navigate(`${redirectUrl}?code=${code}`)
        })
      } else {
        navigate('/home')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="password-login">
      <div className="login-phone">
        <Input
          className="input-with-hint"
          value={phone}
          maxLength={20}
          onChange={onMobileChange}
          placeholder="请输入 11 位手机号"
          status={mobileError ? 'error' : undefined}
        />
      </div>
      <div className="login-password">
        <Input.Password
          className="input-with-hint"
          value={password}
          autoComplete="new-password"
          visibilityToggle={{ visible: false }}
          onChange={onPasswordChange}
          placeholder="请输入密码"
          onPressEnter={onPressEnter}
        />
      </div>

      <div className="op">
        <Checkbox checked={rememberAccount} onChange={onRememberAccountChange}>
          <label>记住账号</label>
        </Checkbox>
        <label className="switch" onClick={switchLoginType}>
          验证码登录
        </label>
      </div>

      <Button
        className="password-login-button"
        disabled={phone.length !== 11 || !password.length || !agreementChecked}
        onClick={doLogin}
        type="primary"
        loading={loading}
      >
        登录
      </Button>
      <div className="login-agree">
        <Checkbox checked={agreementChecked} onChange={onAgreeChange}>
          <label>我已阅读并同意</label>
          <a href="/eula.html" target="_blank">
            《用户协议》
          </a>
          <label>与</label>
          <a href="/privacy_agreement.html" target="_blank">
            《隐私条款》
          </a>
        </Checkbox>
      </div>
    </div>
  )
}

export default memo(PasswordLogin)
