import './style.scss'
import { Modal } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import * as Api from '@/api/account'
import { IProduct, UserStore } from '@/global-states'
import { eventTracking, urlSource } from '@/libs/util'
import PayModal from '../PayModal'

interface IProps {
  open: boolean
  onCancel?: () => void
}
const ActivityModal: FC<IProps> = (props) => {
  const { open, onCancel } = props
  const [product, setProductList] = useState<IProduct[]>()
  const [payModalOpen, setPayModalOpen] = useState(false)
  const [payProduct, setPayProduct] = useState<IProduct>()

  const getProductList = async () => {
    const { list }: { list: IProduct[] } = await Api.getProductList()
    setProductList(list)
  }

  const toPay = () => {
    eventTracking('ActivityModalClick-618')
    onCancel?.()
    setPayProduct(product?.find((p) => p.units === 2 && p.tag))
    setPayModalOpen(true)
  }

  const closeModal = () => {
    onCancel?.()
    UserStore.updateUserPackage()
  }

  useEffect(() => {
    if (open) {
      eventTracking('ActivityModaShow-618')
      sessionStorage.setItem('activity', 'false')
      getProductList()
    }
  }, [open])

  return (
    <>
      <Modal maskClosable={true} className="activity-modal" footer={null} width={900} onCancel={closeModal} open={open}>
        <img src={urlSource('hiflys/personal-activity/images/activity-modal.webp')} onClick={toPay} />
      </Modal>
      <PayModal
        open={payModalOpen}
        product={payProduct}
        onCancel={() => {
          setPayModalOpen(false)
          closeModal()
        }}
        hiddenInviteCode={true}
        showTips={true}
      />
    </>
  )
}

export default memo(ActivityModal)
