import { authApi } from '@/api'

export const login = (params: any) => {
  return authApi.post('api/v1/meta/login', params)
}

export const sendSms = (params: any) => {
  return authApi.post('api/v1/meta/send_sms', params)
}

export const aliyunCaptchaVerify = (params: any) => {
  return authApi.post('api/v1/meta/check_captcha', params)
}
