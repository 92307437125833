import './style.scss'
import { message, Modal } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { FC, useEffect, useState } from 'react'
import { cdnApi } from '@/api'
import * as homeApi from '@/api/home'
import AvatarAction1 from '@/assets/avatar-action1.png'
import AvatarAction2 from '@/assets/avatar-action2.png'
import AvatarAction3 from '@/assets/avatar-action3.png'
import AvatarAction4 from '@/assets/avatar-action4.png'
import AvatarAction5 from '@/assets/avatar-action5.png'

interface IProps {
  open: boolean
  onCancel?: () => void
  onOk?: () => void
}

const CreateFaceModal: FC<IProps> = (props) => {
  const { open, onCancel, onOk } = props
  const [nationalityGenders, setNationalityGenders] = useState<any[]>([])
  const [ageGroups, setAgeGroups] = useState<any[]>([])
  const [hairStyles, setHairStyles] = useState<any[]>([])
  const [hairColors, setHairColors] = useState<any[]>([])
  const [prompts, setPrompts] = useState<any[]>([])
  const [selectNationalityGender, setSelectNationalityGender] = useState<any>({})
  const [selectAgeGroup, setSelectAgeGroup] = useState<any>({})
  const [selectHairStyle, setSelectHairStyle] = useState<any>({})
  const [selectHairColor, setSelectHairColor] = useState<any>({})
  const [description, setDescription] = useState('')
  const [faceCost, setFaceCost] = useState<any>()
  const [sensitiveWords, setSensitiveWords] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (open) {
      getNationalityGenders()
      getAgeGroups()
      getHairStyles()
      getHairColors()
      getFaceCost()
      getPrompts()
      initSensitiveWords()
      setDescription('')
    }
  }, [open])

  const getNationalityGenders = async () => {
    const res: any = await homeApi.getNationalGenders()
    const newTags: any[] = []
    ;(res.tags || []).forEach((t: any, index: number) => {
      const i = Math.ceil((index + 1) / 2) - 1
      if (newTags[i]) {
        newTags[i].push(t)
      } else {
        newTags[i] = [t]
      }
    })
    setNationalityGenders(newTags)
    setSelectNationalityGender(newTags[0]?.[0] || {})
  }

  const getAgeGroups = async () => {
    const res = await homeApi.getAgeGroups()
    const tags = res?.tags || []
    setAgeGroups(tags)
    setSelectAgeGroup(tags[0] || {})
  }

  const getHairStyles = async () => {
    const res = await homeApi.getHairStyles()
    const tags = res?.tags || []
    setHairStyles(tags)
    setSelectHairStyle(tags[0] || {})
  }

  const getHairColors = async () => {
    const res = await homeApi.getHairColors()
    const tags = res?.tags || []
    setHairColors(tags)
    setSelectHairColor(tags[0] || {})
  }

  const getFaceCost = async () => {
    const res = await homeApi.getFaceCost()
    setFaceCost(res)
  }

  const getPrompts = async () => {
    const res = await homeApi.getPromptTemplates()
    setPrompts(res?.tags || [])
  }

  const initSensitiveWords = async () => {
    const content = await cdnApi.get(`hiflys/sensitive_words_lines.json?date=${Date.now()}`)
    setSensitiveWords(content || [])
  }

  const checkSensitive = () => {
    const errorWords: any[] = []
    sensitiveWords.forEach((words) => {
      if (words && words.length && description.indexOf(words) > -1 && errorWords.indexOf(words) < 0) {
        errorWords.push(words)
      }
    })
    return errorWords
  }

  const createFace = async () => {
    const errorWords = checkSensitive()
    if (errorWords?.length) {
      return message.error(
        <div style={{ padding: '12px' }}>
          您提交的输入描述词包含敏感字符<label className="red">（{errorWords.join('、')}</label>），请修改后再提交
        </div>
      )
    }

    setLoading(true)

    try {
      const params = {
        face_tag: {
          nationality: selectNationalityGender.nationality,
          gender: selectNationalityGender.gender,
          age_group: selectAgeGroup.age_group,
          hair_style: selectHairStyle.hair_style,
          hair_color: selectHairColor.hair_color
        },
        additional_prompt: description
      }

      const res = await homeApi.submitFaceTask(params)

      if (res.task_id) {
        onCancel?.()
        onOk?.()
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      className="commom-modal create-face-modal"
      title="生成人脸"
      open={open}
      width={700}
      onCancel={onCancel}
      okText="生成人脸"
      onOk={createFace}
      okButtonProps={{ loading: loading }}
    >
      <div className="page-main">
        <div className="action-box">
          <div className="title">
            <img className="img" src={AvatarAction1} />
            <span>选择国别/性别</span>
          </div>
          <div className="img-list">
            {nationalityGenders.map((data, index) => {
              return (
                <div className="img-list-box" key={index}>
                  {data.map((ng: any) => (
                    <div
                      className={`img-list-item ${
                        ng.display_name === selectNationalityGender.display_name ? 'actived' : ''
                      }`}
                      onClick={() => setSelectNationalityGender(ng)}
                      key={ng.display_name}
                    >
                      <div className="top">
                        <img className="img" src={ng.image_url} />
                      </div>
                      <div className="bottom">{ng.display_name}</div>
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
        </div>

        <div className="action-box">
          <div className="title">
            <img className="img" src={AvatarAction2} />
            <span>选择年龄</span>
          </div>
          <div className="button-list">
            {ageGroups.map((group) => (
              <div
                className={`list-item ${group.display_name === selectAgeGroup.display_name ? 'actived' : ''}`}
                onClick={() => setSelectAgeGroup(group)}
                key={group.display_name}
              >
                <span>{group.display_name}</span>
                <span className="text-gray">
                  （{group.sidenote}
                  {')'}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="action-box">
          <div className="title">
            <img className="img" src={AvatarAction3} />
            <span>选择发型</span>
          </div>

          <div className="button-list">
            {hairStyles.map((s) => (
              <div
                className={`list-item ${s.display_name === selectHairStyle.display_name ? 'actived' : ''}`}
                onClick={() => setSelectHairStyle(s)}
                key={s.display_name}
              >
                <span>{s.display_name}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="action-box">
          <div className="title">
            <img className="img" src={AvatarAction4} />
            <span>选择发色</span>
          </div>

          <div className="button-list">
            {hairColors.map((color) => (
              <div
                className={`list-item ${color.display_name === selectHairColor.display_name ? 'actived' : ''}`}
                onClick={() => setSelectHairColor(color)}
                key={color.display_name}
              >
                <span>{color.display_name}</span>
                <span className="color" style={{ backgroundColor: color.display_color }}></span>
              </div>
            ))}
          </div>
        </div>

        <div className="action-box pd0">
          <div className="title">
            <img className="img" src={AvatarAction5} />
            <span>输入描述词（选填）</span>
          </div>

          <TextArea
            className="modal-input"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="输入脸部描述语，或直接选择下方推荐描述词"
            maxLength={20}
            showCount
          />
        </div>

        <div className="prompt-list">
          {prompts.map((p) => (
            <div
              className="prompt-list-item"
              key={p.display_name}
              onClick={() => setDescription(p.additional_prompt_template)}
            >
              {p.display_name}
            </div>
          ))}
        </div>
      </div>

      {faceCost && (
        <div className="modal-bottom">
          <div className="fee">
            <span>本次生成：{faceCost.quota_per_task || 0}张</span>
            <span>当天剩余：{faceCost.quota_left || 0}张</span>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default CreateFaceModal
