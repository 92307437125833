import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/browser'
import App from './App'

const env = import.meta.env

if (env.MODE === 'production') {
  console.log('Sentry init')
  Sentry.init({ dsn: 'https://b47b1be4c3f342c7a2b2b595aba0062d@sentry.lingverse.co/21' })
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />)
