import './style.scss'
import { Input, InputNumber, message, Modal } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useEffect, useRef, useState } from 'react'
import * as uuid from 'uuid'
import * as Api from '@/api/account'
import { CheckOutlined, FormOutlined, SyncOutlined } from '@ant-design/icons'

interface IProps {
  apiInfo: any
  onSave: () => void
}

const VipCompany: FC<IProps> = (props) => {
  const { apiInfo, onSave } = props
  const [creditEdit, setCreditEdit] = useState(false)
  const [credit, setCredit] = useState<any>()
  const [callbackUrlEdit, setCallbackUrlEdit] = useState(false)
  const [callbackUrl, setCallbackUrl] = useState<any>()
  const token = useRef<any>()

  useEffect(() => {
    if (apiInfo?.token) {
      setCredit(apiInfo.warning_credits_watermark)
      setCallbackUrl(apiInfo.callback_url)
      token.current = apiInfo.token
    }
  }, [apiInfo])

  const refreshToken = () => {
    Modal.confirm({
      title: '温馨提示',
      content: '该操作将重置现有token，你确认要执行吗？',
      onOk: () => {
        token.current = uuid.v4().replace(/-/g, '').substring(0, 16)
        save()
      }
    })
  }

  const editCredit = () => {
    setCreditEdit(true)
  }

  const saveEditCredit = () => {
    if (credit < 5000) {
      return message.warning('预警提醒最低值为5000积分')
    }
    setCreditEdit(false)
    save()
  }

  const editCallbackUrl = () => {
    setCallbackUrlEdit(true)
    setCallbackUrl(apiInfo.callback_url)
  }

  const saveEditCallbackUrl = () => {
    setCallbackUrlEdit(false)
    save()
  }

  const save = async () => {
    await Api.editApiInfo({
      token: token.current,
      callback_url: callbackUrl,
      warning_credits_watermark: credit || 5000
    })
    message.success('修改成功')
    onSave()
  }

  return (
    <div className="vip-company">
      <div className="title">
        API 明细
        <span>
          （
          <a target="_blank" href="https://api.lingverse.co/hifly.html" rel="noreferrer">
            查看API文档
          </a>
          ）
        </span>
      </div>
      <div className="form-item">
        <label className="left">token：</label>
        <label className="right">{apiInfo.token}</label>
        <label className="op" onClick={refreshToken}>
          <SyncOutlined />
        </label>
      </div>
      <div className="form-item">
        <label className="left">有效期：</label>
        <label className="right">{dayjs(apiInfo.expire_time * 1000).format('YYYY-MM-DD HH:mm')}</label>
      </div>
      <div className="form-item">
        <label className="left">回调地址：</label>
        <label className="right">
          {callbackUrlEdit ? (
            <Input
              className="form-input"
              placeholder="在作品处理完成或失败时，触发作品状态回调，可不填。"
              value={callbackUrl}
              onChange={(e) => setCallbackUrl(e.target.value)}
              maxLength={100}
            />
          ) : (
            apiInfo.callback_url
          )}
        </label>
        <label className="op">
          {callbackUrlEdit ? (
            <CheckOutlined onClick={saveEditCallbackUrl} />
          ) : (
            <FormOutlined onClick={editCallbackUrl} />
          )}
        </label>
      </div>
      <div className="form-item">
        <label className="left">预警提醒：</label>
        <label className="right">
          积分低于
          {creditEdit ? (
            <InputNumber value={credit} onChange={(e) => setCredit(e)} min={0} max={999999} maxLength={6} />
          ) : (
            apiInfo.warning_credits_watermark
          )}
          时，短信提醒
        </label>
        <label className="op">
          {creditEdit ? <CheckOutlined onClick={saveEditCredit} /> : <FormOutlined onClick={editCredit} />}
        </label>
      </div>
    </div>
  )
}

export default memo(VipCompany)
