import './style.scss'
import { FC, memo } from 'react'
import Banner1 from '@/assets/banner-new.webp'
import Logo from '@/assets/login-logo.png'
import MobileLogin from './components/mobie-login'
import PasswordLogin from './components/password-login'
import { LoginStore } from './states'

const Login: FC = () => {
  const { loginType } = LoginStore

  return (
    <div className="page-login">
      {/* <img className="bg" src={Bg} /> */}
      <div className="page-login-main">
        <div className="left">
          <img src={Banner1} />
        </div>
        <div className="right">
          <img className="logo" src={Logo} />
          <div className="title">Welcome to Hifly</div>
          <div className="login-box">{loginType === 'mobile' ? <MobileLogin /> : <PasswordLogin />}</div>
        </div>
      </div>
      {(location.href.includes('hifly.cc') || location.href.includes('hi.lingverse.co')) && (
        <div className="copyright">
          Copyright © 2024 上海灵之宇技术有限公司
          <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" rel="noreferrer">
            沪ICP备2022030268号-5
          </a>
          <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch" rel="noreferrer">
            沪公网安备31011002006530号
          </a>
        </div>
      )}
    </div>
  )
}

export default memo(Login)
