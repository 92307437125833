import './style.scss'
import { Button, message, Modal, Progress } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'
import { FC, useEffect, useRef, useState } from 'react'
import { cmsApi } from '@/api'
import * as homeApi from '@/api/home'
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons'

interface IProps {
  open: boolean
  onCancel?: () => void
  onOk?: () => void
}

const CreateActionModal: FC<IProps> = (props) => {
  const { open, onCancel, onOk } = props
  const [currentStep, setCurrentStep] = useState(0)
  const [videoFile, setVideoFile] = useState<RcFile>(undefined as any)
  const [videoUrl, setVideoUrl] = useState<string>(undefined as any)
  const [videoInfo, setVideoInfo] = useState<any>({})
  const [percent, setPercent] = useState(0)
  const cancelTokenSource = useRef<any>()
  const [loading, setLoading] = useState(false)
  const durationRef = useRef<any>()

  useEffect(() => {
    if (open) {
      restartUpload()
    }
  }, [open])

  useEffect(() => {
    if (videoFile) {
      setCurrentStep(1)
      setVideoUrl(URL.createObjectURL(videoFile))
    } else {
      setVideoUrl(undefined as any)
    }
  }, [videoFile])

  const beforeVideoUpload = async (file: RcFile) => {
    return new Promise((resolve) => {
      const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
      if (['mp4', 'mov'].includes(type)) {
        const size = file?.size || 0
        if (size / 1000 / 1000 > 500) {
          message.warning('视频大小不能大于 500MB')
          resolve(false)
        }
        const videoElement = document.createElement('video')
        videoElement.src = URL.createObjectURL(file)
        videoElement.addEventListener('loadedmetadata', () => {
          console.log('videoElement', videoElement.videoWidth)

          if (videoElement.videoWidth === 0) {
            message.warning('视频处理失败, 建议你使用h264编码格式的mp4文件')
            return resolve(false)
          }

          if (videoElement.videoWidth < 360 || videoElement.videoWidth > 4096) {
            message.warning('视频素材分辨率要求为360p～4K')
            return resolve(false)
          }

          if (videoElement.duration < 10 || videoElement.duration > 1800) {
            message.warning('视频时长要求为10秒～30分钟')
            return resolve(false)
          }
          durationRef.current = Math.ceil(videoElement.duration) * 1000

          resolve(true)
        })
      } else {
        message.warning('请上传mp4、mov格式视频文件')
        resolve(false)
      }
    })
  }

  const onDrop = async (file: RcFile) => {
    const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
    if (!['mp4', 'mov'].includes(type)) {
      message.warning('请上传mp4、mov格式视频文件')
    }
  }

  // media_type -  0 未定义  1 视频  2 音频
  const uploadFile = async (file: RcFile, media_type: 0 | 1 | 2) => {
    try {
      const segs = (file.name || '').split(/\./)
      const { upload_url, oss_key, content_type } =
        (await cmsApi.post('upload_url', {
          extension: segs[segs.length - 1],
          media_type
        })) || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      cancelTokenSource.current = axios.CancelToken.source()
      setPercent(0)
      setCurrentStep(1)

      await cmsApi.upload(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        onUploadProgress: (progress) => {
          const percent = Math.round((progress.progress || 0) * 100)
          setPercent(percent)
        },
        headers: {
          'Content-Type': content_type
        },
        cancelToken: cancelTokenSource.current?.token
      })

      setCurrentStep(2)
      if (media_type === 1) {
        setVideoInfo({
          ...(videoInfo || {}),
          title: segs[0],
          oss_key
        })
      }
    } catch (err: any) {
      if (err?.code !== 'ERR_CANCELED') {
        message.error(err?.message || err)
      }
    }
  }

  const restartUpload = () => {
    cancelUpload()
    setCurrentStep(0)
    setPercent(0)
    setVideoFile(undefined as any)
    setVideoInfo(undefined)
  }

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.current?.cancel('取消上传')
    }
  }

  const completeCreate = async () => {
    if (!videoInfo?.oss_key) {
      return message.warning('请上传视频')
    }

    setLoading(true)
    try {
      await homeApi.submitPrivateAction({
        oss_key: videoInfo?.oss_key,
        duration_ms: durationRef.current
      })
      message.success('提交成功')
      onCancel?.()
      onOk?.()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      className="commom-modal create-action-modal"
      open={open}
      title="新增我的动作"
      width={600}
      onCancel={onCancel}
      okText="提交"
      okButtonProps={{ loading: loading }}
      onOk={completeCreate}
    >
      <div className="main">
        <div className="form-item">
          <div className="title">上传视频</div>

          <div className="warning">
            <h4>视频要求</h4>
            <div className="desc">
              <div>
                <label className="label">视频方向：</label>
                <label>横向或纵向</label>
              </div>
              <div>
                <label className="label">文件格式：</label>
                <label>mp4、mov</label>
              </div>
              <div>
                <label className="label">视频时长：</label>
                <label>10秒~30分钟</label>
              </div>
              <div>
                <label className="label">分辨率：</label>
                <label>360p~4K</label>
              </div>
              <div>
                <label className="label">文件大小：</label>
                <label>小于500MB</label>
              </div>
            </div>
          </div>
          <div className="form-box">
            {currentStep === 0 && (
              <div className="content">
                <Dragger
                  accept=".mp4,.mov"
                  showUploadList={false}
                  beforeUpload={async (file) => {
                    const flag = (await beforeVideoUpload(file)) as any
                    if (flag) {
                      setVideoFile(file)
                      uploadFile(file, 1)
                    }
                    return flag
                  }}
                  onDrop={(e) => onDrop(e.dataTransfer.files?.[0] as any)}
                >
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p className="ant-upload-text">请上传一段视频，作为驱动数字人的底版视频</p>
                  <p className="ant-upload-hint">将文件拖到此处，或点击此区域上传</p>
                </Dragger>
              </div>
            )}
            {currentStep === 1 && (
              <div className="step-progress">
                <div className="step-progress-content">
                  <div className="percent">{percent}%</div>
                  <Progress percent={percent} showInfo={false} />
                  <div className="tips">视频上传中</div>
                </div>
                <div className="btns">
                  <Button onClick={restartUpload}>取消</Button>
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="step-view">
                <div className="step-view-box video">
                  <video controls src={videoUrl} />
                  <div className="trash" onClick={restartUpload}>
                    <DeleteOutlined />
                  </div>
                </div>
                <div className="btns">
                  <div onClick={restartUpload}>重新上传</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CreateActionModal
