export const Check = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      version="1.1"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <defs>
        <clipPath id="master_svg0_710_13448">
          <rect x="0" y="0" width="16" height="16" rx="0" />
        </clipPath>
        <clipPath id="master_svg1_710_13449">
          <rect x="-4" y="-4" width="24" height="24" rx="0" />
        </clipPath>
      </defs>
      <g clipPath="url(#master_svg0_710_13448)">
        <g clipPath="url(#master_svg1_710_13449)">
          <g>
            <path
              d="M11.69886019454956,4.073714256286621L6.833140194549561,8.937714256286622L4.294290194549561,6.398854256286621L2.5960001945495605,8.097144256286622L6.840570194549561,12.341714256286622L8.53886019454956,10.643424256286622L8.53143019454956,10.635994256286622L13.39710019454956,5.770284256286621L11.69886019454956,4.073714256286621Z"
              fill="#17C5A2"
              fillOpacity="1"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
