import './style.scss'
import { message, Modal } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import * as Api from '@/api/home'
import CouponGift from '@/assets/coupon-gift.webp'

const Coupon: FC = () => {
  const [open, setOpen] = useState(false)
  const [coupon, setCoupon] = useState<any>()

  useEffect(() => {
    getUnclaimedCoupons()
  }, [])

  const getUnclaimedCoupons = async () => {
    const res = await Api.getUnclaimedCoupons()
    const first_membership_order = res?.list?.find((l: any) => l.name === 'first_membership_order' && l.status === 1)
    if (first_membership_order) {
      setOpen(true)
      setCoupon(first_membership_order)
    }
  }

  const receiveCoupon = async () => {
    await Api.receviceCoupon(coupon.id)
    setOpen(false)
    message.success('领取成功')
  }

  return (
    <>
      <Modal
        maskClosable={true}
        className="coupon-modal"
        onCancel={() => setOpen(false)}
        footer={null}
        width={560}
        open={open}
      >
        <div className="coupon-modal-left">
          <div className="title">首购优惠</div>
          <div className="desc">新用户限时立减</div>
          <img src={CouponGift} />
        </div>
        <div className="coupon-modal-right">
          <div className="title">首购限时立减券</div>
          <div className="desc">新用户首次购买会员限时立减</div>
          <div className="amount">
            <label>{coupon?.deduct_amount / 100}</label>
            <label className="unit">元</label>
          </div>
          <div className="btn" onClick={receiveCoupon}>
            立即领取
          </div>
          <div className="red">*本券自领取时间起24小时有效</div>
        </div>
      </Modal>
    </>
  )
}

export default memo(Coupon)
