import './style.scss'
import { Button, Checkbox, message, Modal, QRCode } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import * as Api from '@/api/account'
import Person from '@/assets/user.png'
import { UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import { AlipayOutlined, WechatOutlined } from '@ant-design/icons'

interface Iil {
  credits: number
  discounted_price: number
  id: number
  original_price: number
  badge?: {
    background_color: string
    content: string
    content_color: string
  }
}

interface IProps {
  open: boolean
  onCancel?: () => void
  onSuccess?: () => void
}

const PayPointModal: FC<IProps> = (props) => {
  const { open, onCancel, onSuccess } = props
  const { phone, userPackage } = UserStore
  const [payMethod, setPayMethod] = useState(1)
  const [oilProduct, setOilProduct] = useState<Iil[]>()
  const [checkedProduct, setCheckedProduct] = useState<Iil>()
  const [loading, setLoading] = useState(false)
  const [payUrl, setPayUrl] = useState('')
  const [checked, setChecked] = useState(true)
  const payTimer = useRef<any>()

  const vip = useMemo(() => {
    return !!userPackage?.membership_expire_time
  }, [userPackage])

  const vipExpired = useMemo(() => {
    return !!userPackage?.membership_expired
  }, [userPackage])

  useEffect(() => {
    setPayMethod(1)
    setPayUrl('')
    clearPayTimer()
    open && getProduct()
  }, [open])

  const getProduct = async () => {
    const { list }: { list: Iil[] } = await Api.getPermanentProductList()
    setOilProduct(list || [])
    setCheckedProduct(list?.[0])
  }

  const changePayMethod = (method: number) => {
    setPayMethod(method)
  }

  const toPay = async () => {
    setLoading(true)

    try {
      const { pay_order_id } = await Api.permanentOrderCreate({
        package_id: checkedProduct?.id,
        client_type: 1
      })

      const buyParams = {
        orderId: pay_order_id,
        payType: payMethod === 0 ? 'ALIPAY' : 'WECHATPAY'
      }

      eventTracking('CreditsPayNow', buyParams)

      const buyRes = await Api.paymentInit(buyParams)
      if (payMethod === 1) {
        setPayUrl(buyRes?.pay_list.wechatpayNative?.code_url)
        paymentCheck(pay_order_id)
        payTimer.current = setInterval(() => {
          paymentCheck(pay_order_id)
        }, 3000)
      } else {
        location.href = buyRes?.pay_list?.alipay
      }
    } finally {
      setLoading(false)
    }
  }

  const paymentCheck = async (order_id: string) => {
    const res = await Api.paymentCheck({
      order_id
    })
    if (res?.is_pay === 1) {
      message.success('支付成功')
      clearPayTimer()
      onCancel?.()
      onSuccess?.()
    }
  }

  const clearPayTimer = () => {
    if (payTimer.current) {
      clearInterval(payTimer.current)
      payTimer.current = undefined
    }
  }

  return (
    <>
      <Modal className="pay-point-modal" footer={null} width={660} onCancel={() => onCancel?.()} open={open}>
        <div className="pay-content">
          <div className="pay-content-header">
            <img src={Person} />
            <div className="user-info">
              <div className="phone">
                <label>{phone}</label>
                {vip && !vipExpired && (
                  <label className="tag">{userPackage?.current_membership_package_title}会员</label>
                )}
              </div>
              {vip && userPackage ? (
                <>
                  {vipExpired ? (
                    <label className="text red">
                      套餐已于{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}过期
                    </label>
                  ) : (
                    <label className="text">
                      到期时间：{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}
                    </label>
                  )}
                </>
              ) : (
                <label className="text">未开通会员</label>
              )}
            </div>
          </div>
          <div className="pay-content-main">
            {!payUrl ? (
              <>
                <div className="plan">
                  {oilProduct?.map((o) => (
                    <div
                      className={`plan-item ${checkedProduct?.id === o.id ? 'actived' : ''}`}
                      key={o.id}
                      onClick={() => setCheckedProduct(o)}
                    >
                      <div className="score">{o.credits}积分</div>
                      <div className="price">
                        <div className="discount-price">
                          <label className="unit">¥</label>
                          {(o.discounted_price || 0) / 100}
                        </div>
                        {o.original_price !== o.discounted_price ? (
                          <div className="origin-price">¥{(o.original_price || 0) / 100}</div>
                        ) : (
                          <div className="origin-price"></div>
                        )}
                      </div>
                      {!!o.badge && (
                        <div
                          className="tag"
                          style={{
                            backgroundColor: o.badge.background_color,
                            color: o.badge.content_color
                          }}
                        >
                          {o.badge.content}
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className="pay-rules">
                  <div className="name">
                    <a
                      target="_blank"
                      href="https://lingverse.feishu.cn/sheets/IJeQsahvdhqX5btBSzycBKPRnr0"
                      rel="noreferrer"
                    >
                      积分说明
                    </a>
                  </div>
                  <ul>
                    <li>充值积分不会清零，即充即用；</li>
                  </ul>
                </div>

                <div className="pay-methods">
                  <div className="name">支付方式</div>
                  <div className="pay-list">
                    <div
                      className={`item wechat ${payMethod === 1 ? 'actived' : ''}`}
                      onClick={changePayMethod.bind(this, 1)}
                    >
                      <WechatOutlined />
                      <div>微信支付</div>
                    </div>
                    <div
                      className={`item alipay ${payMethod === 0 ? 'actived' : ''}`}
                      onClick={changePayMethod.bind(this, 0)}
                    >
                      <AlipayOutlined />
                      <div>支付宝</div>
                    </div>
                  </div>
                </div>

                <div className="pay">
                  <div className="name">应付金额</div>
                  <div className="money">￥{(checkedProduct?.discounted_price || 0) / 100}</div>
                </div>

                <div className="btn">
                  <Button disabled={!checked} loading={loading} type="primary" onClick={toPay}>
                    立即支付
                  </Button>
                </div>
                <div className="checkbox">
                  <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}></Checkbox>
                  <div>
                    我已阅读并同意
                    <a href="/point_agreement.html" target="_blank">
                      《积分充值协议》
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <div className="pay-qrcode">
                <div className="pay-desc">
                  <div>微信扫一扫付款（元）</div>
                  <div className="mount">{(checkedProduct?.discounted_price || 0) / 100}</div>
                </div>
                <QRCode value={payUrl} />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default memo(PayPointModal)
