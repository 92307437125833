import './style.scss'
import { Button, Modal } from 'antd'
import { FC, memo, useRef } from 'react'
import { CreateVideo } from '@/assets/svg'

interface IProps {
  preview: {
    url: string
    title?: string
    id?: number
    type?: string
    tip?: string
    source_type?: number
  }
  btnShow?: boolean
  btnClick?: () => void
  onCancel?: () => void
}

const VideoModal: FC<IProps> = (props) => {
  const { preview, onCancel, btnShow, btnClick } = props
  const videoRef = useRef<HTMLVideoElement>(null)

  if (!preview) return null

  return (
    <Modal
      title={preview?.title || '预览'}
      open={!!preview}
      width="56%"
      footer={null}
      onCancel={() => {
        videoRef.current?.pause()
        onCancel?.()
      }}
      className="commom-modal"
    >
      <div className="video-modal-content">
        <div className="content-video">
          {preview.source_type === 3 ? (
            <img src={preview.url} />
          ) : (
            <>
              {preview?.type === 'audio' ? (
                <audio ref={videoRef} autoPlay controls src={preview.url} />
              ) : (
                <video ref={videoRef} autoPlay controls src={preview.url} />
              )}
            </>
          )}
        </div>
        {btnShow && (
          <div className="btns">
            <Button type="primary" icon={<CreateVideo width={20} height={20} />} onClick={btnClick}>
              创建新视频
            </Button>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default memo(VideoModal)
