import './style.scss'
import { FC, memo, PropsWithChildren, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as Api from '@/api/home'
import QrcodeVip from '@/assets/qrcode-vip.webp'
import Qrcode from '@/assets/qrcode.webp'
import { Home, Video } from '@/assets/svg'
import { User } from '@/assets/svg/user'
import { UserStore } from '@/global-states'
import { AppstoreFilled, CopyrightCircleFilled, PayCircleFilled } from '@ant-design/icons'

const LayoutMenu: FC = () => {
  const { pathname } = useLocation()
  const { userPackage } = UserStore
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    getData()
  }, [])

  const Item: FC<PropsWithChildren<{ current: string; path: string }>> = (props) => {
    const { children, current, path } = props
    return (
      <Link to={`../${path}`} className="menu-link">
        <div className={`panel ${current === path ? 'selected' : ''}`}>{children}</div>
      </Link>
    )
  }

  const getData = async () => {
    const [merchandises, markets] = await Promise.all([Api.getMerchandises(), Api.getAvatarMarkets()])
    setData([
      ...(markets.list || []).filter((l: any) => l.status !== 3),
      ...(merchandises.list || []).filter((l: any) => l.status !== 2)
    ])
  }

  return (
    <div className="layout-menu">
      <div className="layout-menu-main">
        <Item current={pathname} path="/home">
          <Home className="icon" />
          数字分身
        </Item>
        <Item current={pathname} path="/clone">
          <CopyrightCircleFilled className="clone" />
          声音克隆
        </Item>
        <Item current={pathname} path="/avatar-market">
          <AppstoreFilled className="market" />
          数字市场
          {data.length > 0 && <div className="tag">上新</div>}
        </Item>
        <Item current={pathname} path="/video">
          <Video className="icon" />
          作品管理
        </Item>
        <Item current={pathname} path="/invite">
          <PayCircleFilled className="icon" />
          免费积分
        </Item>
        <Item current={pathname} path="/setting">
          <User className="icon" />
          个人中心
        </Item>
      </div>

      {userPackage && (
        <>
          {userPackage.membership_expire_time && !userPackage.membership_expired ? (
            <div className="bottom">
              <img className="qrcode" src={QrcodeVip} />
            </div>
          ) : (
            <div className="bottom">
              <img className="qrcode" src={Qrcode} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default memo(LayoutMenu)
