import './style.scss'
import { Dropdown, Input, message, Modal, Popover, Tooltip } from 'antd'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as AccountApi from '@/api/account'
import * as Api from '@/api/home'
import AvatarAi from '@/assets/avatar-ai.webp'
import AvatarPhoto from '@/assets/avatar-photo.webp'
import AvatarVideo from '@/assets/avatar-video.webp'
import { CreateVideo, Ellipsis, Play } from '@/assets/svg'
import { Arrow } from '@/assets/svg/arrow'
import PlanModal from '@/components/PlanModal'
import VideoModal from '@/components/VideoModal'
import { IUserPackage } from '@/global-states'
import { eventTracking, urlSource } from '@/libs/util'
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined
} from '@ant-design/icons'
import CreateAvatarDrawer from './components/CreatAvatarDrawer'
import CreateAiAvatarDrawer from './components/CreateAiAvatarDrawer'
import CreatePhotoAvatarDrawer from './components/CreatePhotoAvatarDrawer'

const Index: FC = () => {
  const navigate = useNavigate()
  const [editId, setEditId] = useState<any>()
  const [preview, setPreview] = useState<any>()
  const [openId, setOpenId] = useState<any>()
  const [data, setData] = useState<any[]>([])
  const [globalData, setGlobalData] = useState<any[]>([])
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false)
  const [createPhotoDrawerOpen, setCreatePhotoDrawerOpen] = useState(false)
  const [createAIDrawerOpen, setCreateAIModalOpen] = useState(false)
  const [modelModal, setModelModal] = useState(false)
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const timeRef = useRef<any>()

  useEffect(() => {
    eventTracking('InstantAvatarPage')
    getData()
    getGlobalData()
    timeRef.current = setInterval(() => {
      getData()
    }, 5000)

    return () => {
      clearInterval(timeRef.current)
    }
  }, [])

  const getData = async () => {
    const [digitalHumans, unFinishTask]: [any, any] = await Promise.all([Api.getDigitalHumans(), Api.getHumanTasks()])

    setData([...(unFinishTask?.unfinished_tasks || []), ...(digitalHumans?.list || [])])

    if (
      !unFinishTask?.unfinished_tasks?.length &&
      !(digitalHumans?.list || []).filter((d: any) => d.status === 1)?.length
    ) {
      clearInterval(timeRef.current)
    }
  }

  const getGlobalData = async () => {
    const res = await Api.getGlobalDigitalHumans()
    setGlobalData(res.list || [])
  }

  const previewVideo = (d: any) => {
    setPreview({
      url: d.video_url,
      title: d.title,
      tip: d.tip,
      id: d.id,
      source_type: d.source_type
    })
  }

  const onValueChange = (e: any) => {
    setData(
      data.map((d) => {
        return d.id === editId
          ? {
              ...d,
              title: e.target.value
            }
          : d
      })
    )
  }

  const onGlobalValueChange = (e: any) => {
    setGlobalData(
      globalData.map((d) => {
        return d.id === editId
          ? {
              ...d,
              title: e.target.value
            }
          : d
      })
    )
  }

  const saveChange = async (d: any) => {
    setEditId('')
    if (d.title.trim()) {
      Api.updateDigitalHumans(d.id, {
        title: d.title
      })
    } else {
      getData()
    }
  }

  const renameVideo = (id: any, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    setEditId(id)
  }

  const deleteVideo = async (id: any, e: any) => {
    e.stopPropagation()
    Modal.confirm({
      title: '删除数字分身',
      content: '数字分身删除后不可恢复，确认删除数字分身？',
      onOk: async () => {
        setOpenId(undefined)
        await Api.deleteDigitalHumans(id)
        getData()
        message.success('删除成功')
      }
    })
  }

  const toChooseModel = () => {
    setModelModal(true)
  }

  const toCreateAvatar = () => {
    eventTracking('InstantCloneClick')
    setModelModal(false)
    setCreateDrawerOpen(true)
  }

  const toCreatePhotoAvatar = () => {
    eventTracking('InstantPhotoCloneClick')
    setModelModal(false)
    setCreatePhotoDrawerOpen(true)
  }

  const toCreateAIAvatar = async () => {
    eventTracking('InstantAICloneClick')
    const res: IUserPackage = await AccountApi.getUserPackage()
    setModelModal(false)
    if (res && (res.current_membership_level || 0) < 20) {
      return upgradePlan()
    }
    setCreateAIModalOpen(true)
  }

  const upgradePlan = () => {
    Modal.confirm({
      title: '当前会员等级不够，无法使用',
      content: <div>AI生成数字演员是尊享版及以上会员专属功能，请您确认当前会员等级是否匹配</div>,
      okText: '升级会员',
      cancelText: '取消',
      onOk: () => {
        setPlanModalOpen(true)
      }
    })
  }

  const toCreateVideo = (d: any) => {
    eventTracking('CreateAvatarClick', {
      avatarId: d.id
    })
    if (d.tip) {
      return Modal.confirm({
        title: '温馨提示',
        content: `当前数字人可能存在“${d.tip}”的情况，使用其创作有可能导致生成效果不好。建议先检查原视频，使用符合要求的视频来复刻`,
        okText: '继续创作',
        onOk: () => {
          navigate(`/create-video/${d.id}`)
        }
      })
    }
    navigate(`/create-video/${d.id}`)
  }

  const onCreateSuccess = () => {
    getData()
    clearInterval(timeRef.current)
    timeRef.current = setInterval(() => {
      getData()
    }, 5000)
  }

  return (
    <div className="page-common page-home">
      <div className="common-title">
        <div className="text">我的数字分身{data.length > 0 ? `（${data.length}）` : null}</div>
      </div>
      <div className="page-container">
        <div className="list-box">
          <div className="box-main empty" onClick={toChooseModel}>
            <div className="center">
              <div className="t">数字分身</div>
              <div className="desc ellipsis">上传视频, 快速复刻数字分身</div>
              <div className="button">
                快速复刻
                <Arrow />
              </div>
            </div>
          </div>
          <div className="box-title"></div>
        </div>
        {data.map((d) => (
          <div className={`list-box ${openId === d.id ? 'hovered' : ''} `} key={d.id}>
            <div className="box-main" onClick={() => !d.task_id && d.status === 2 && previewVideo(d)}>
              <div
                className="bg"
                style={{
                  backgroundImage: `url(${
                    d.task_id ? d.preview_url : d.source_type === 3 ? d.video_url : urlSource(d.video_url, 'video')
                  })`
                }}
              ></div>

              {!d.task_id && d.source_type !== 3 && (
                <div className="play">
                  <Play />
                </div>
              )}

              {!d.task_id && d.status !== 1 && (
                <div className="op">
                  {d.status === 2 && (
                    <div className="btn" onClick={toCreateVideo.bind(this, d)}>
                      <CreateVideo />
                    </div>
                  )}
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: '1',
                          label: (
                            <div className="dropdown-list" onClick={renameVideo.bind(this, d.id)}>
                              <EditOutlined />
                              重命名
                            </div>
                          )
                        },
                        {
                          key: '2',
                          label: (
                            <div className="dropdown-list" onClick={deleteVideo.bind(this, d.id)}>
                              <DeleteOutlined />
                              删除
                            </div>
                          )
                        }
                      ]
                    }}
                    placement="bottom"
                    open={openId === d.id}
                    onOpenChange={(open: boolean) => setOpenId(open ? d.id : undefined)}
                  >
                    <div className="btn" onClick={(e) => e.stopPropagation()}>
                      <Ellipsis />
                    </div>
                  </Dropdown>
                </div>
              )}

              {(d.task_id || d.status === 1) && (
                <div className="mask">
                  <div className="pending">
                    <LoadingOutlined />
                    <div>生成中, 请稍等</div>
                  </div>
                </div>
              )}

              {(d.status === 3 || d.tip) && (
                <div className={`status ${d.status === 3 ? 'error' : ''}`}>
                  {d.status === 3 && <span>生成失败</span>}

                  {d.tip && (
                    <Tooltip placement="bottom" title={d.tip}>
                      <span className="tips">{<InfoCircleOutlined />}</span>
                    </Tooltip>
                  )}
                </div>
              )}

              <div className="photo">
                <img
                  src={d.task_id ? d.preview_url : d.source_type === 3 ? d.video_url : urlSource(d.video_url, 'video')}
                />
              </div>

              {d.special_cost && (
                <Popover
                  content={
                    <div className="common-popover w400" onClick={(e) => e.stopPropagation()}>
                      <div>
                        <strong>创作优惠：</strong>
                      </div>
                      <ul>
                        <li>
                          标有“创作优惠”的数字演员。雇佣后，在创作视频时享受积分消耗优惠（{d.creation_cost_per_second}
                          积分/秒），即：
                          <div className="ml10">a. 使用音频驱动生成视频时：{d.creation_cost_per_second} 积分/秒；</div>
                          <div className="ml10">
                            b. 使用公版声音、声音克隆-基础版生成视频时：{d.creation_cost_per_second + 1} 积分/秒；
                          </div>
                          <div className="ml10">
                            c. 使用声音克隆-高保真生成视频时：{d.creation_cost_per_second + 2} 积分/秒；
                          </div>
                        </li>
                        <li>仅从数字市场雇佣才有“创作优惠”标记，其他方式上传的视频均无法享有；</li>
                      </ul>
                    </div>
                  }
                  placement="bottom"
                >
                  <div className="discount" onClick={(e) => e.stopPropagation()}>
                    创作优惠
                    <ExclamationCircleOutlined />
                  </div>
                </Popover>
              )}
            </div>
            {!d.task_id && d.status !== 1 && (
              <div className="bottom">
                {editId === d.id ? (
                  <Input
                    value={d.title}
                    autoFocus
                    onChange={onValueChange}
                    onBlur={saveChange.bind(this, d)}
                    onPressEnter={saveChange.bind(this, d)}
                  />
                ) : (
                  <div className="box-title">{d.title}</div>
                )}
                {d.status === 2 && (
                  <div className="btn" onClick={toCreateVideo.bind(this, d)}>
                    去创作
                    <CreateVideo />
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="common-title">
        <div className="text">公用数字分身（{globalData.length > 0 ? globalData.length : null}）</div>
      </div>
      <div className="page-container">
        {globalData.map((d) => (
          <div className={`list-box ${openId === d.id ? 'hovered' : ''} `} key={d.id}>
            <div className="box-main" onClick={previewVideo.bind(this, d)}>
              <div
                className="bg"
                style={{
                  backgroundImage: `url(${urlSource(d.video_url, 'video')})`
                }}
              ></div>
              <div className="play">
                <Play />
              </div>

              <div className="op">
                <div className="btn" onClick={toCreateVideo.bind(this, d)}>
                  <CreateVideo />
                </div>
              </div>
              <div className="photo">
                <img src={urlSource(d.video_url, 'video')} />
              </div>
            </div>
            <div className="bottom">
              {editId === d.id ? (
                <Input
                  value={d.title}
                  autoFocus
                  onChange={onGlobalValueChange}
                  onBlur={saveChange.bind(this, d)}
                  onPressEnter={saveChange.bind(this, d)}
                />
              ) : (
                <div className="box-title">{d.title}</div>
              )}
              <div className="btn" onClick={toCreateVideo.bind(this, d)}>
                去创作
                <CreateVideo />
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal open={modelModal} title="模式选择" footer={null} onCancel={() => setModelModal(false)}>
        <div className="avatar-model">
          <div className="model-item" onClick={toCreatePhotoAvatar}>
            <div className="left">
              <img src={AvatarPhoto} />
            </div>
            <div className="right">
              <div className="title">照片生成数字分身</div>
              <div className="desc">上传一张照片，快速复刻自己的数字分身</div>
            </div>
          </div>
          <div className="model-item" onClick={toCreateAvatar}>
            <div className="left">
              <img src={AvatarVideo} />
            </div>
            <div className="right">
              <div className="title">视频生成数字分身</div>
              <div className="desc">上传一段视频，快速复刻自己的数字分身</div>
            </div>
          </div>
          <div className="model-item" onClick={toCreateAIAvatar}>
            <div className="left">
              <img src={AvatarAi} />
            </div>
            <div className="right">
              <div className="title">AI生成数字分身</div>
              <div className="desc">AI生成独一无二的人脸，选择动作融合生成专属数字分身</div>
            </div>
            <div className="tag">尊享专属</div>
          </div>
        </div>
      </Modal>

      <PlanModal open={planModalOpen} onCancel={() => setPlanModalOpen(false)} />

      <VideoModal
        preview={preview}
        onCancel={() => setPreview(undefined)}
        btnShow={true}
        btnClick={toCreateVideo.bind(this, preview)}
      />

      <CreateAvatarDrawer open={createDrawerOpen} onCancel={() => setCreateDrawerOpen(false)} onOk={onCreateSuccess} />

      <CreatePhotoAvatarDrawer
        open={createPhotoDrawerOpen}
        onCancel={() => setCreatePhotoDrawerOpen(false)}
        onOk={onCreateSuccess}
      />

      <CreateAiAvatarDrawer
        open={createAIDrawerOpen}
        onCancel={() => setCreateAIModalOpen(false)}
        onOk={onCreateSuccess}
      />
    </div>
  )
}

export default memo(Index)
