import './style.scss'
import { Popover } from 'antd'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import * as Api from '@/api/home'
import { eventTracking } from '@/libs/util'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import MarketModal from './components/market-modal'

const AvatarMarket: FC = () => {
  const [data, setData] = useState<any[]>([])
  const [marketModalOpen, setMarketModalOpen] = useState(false)
  const [marketDetail, setMarketDetail] = useState<any>()
  const [type, setType] = useState(-1)
  const statusList = [
    {
      id: -1,
      title: '全部'
    },
    {
      id: 1,
      title: '竞拍'
    },
    {
      id: 2,
      title: '一口价'
    }
  ]
  const showData = useMemo(() => {
    if (type === -1) {
      return data
    }

    return data.filter((d) => d.type === type)
  }, [data, type])

  useEffect(() => {
    eventTracking('DigitalStore')
    getData()
  }, [])

  const getData = async () => {
    const [merchandises, markets] = await Promise.all([Api.getMerchandises(), Api.getAvatarMarkets()])
    setData(
      [
        ...(markets.list || []).map((l: any) => ({
          ...l,
          type: 1
        })),
        ...(merchandises.list || []).map((l: any) => ({
          ...l,
          type: 2
        }))
      ].sort((a, b) => a.status - b.status)
    )
  }

  const onMarketClick = (data: any) => {
    eventTracking('DigitalStoreClick', {
      id: data.id,
      name: data.name
    })
    setMarketDetail(data)
    setMarketModalOpen(true)
  }

  const onCancel = () => {
    getData()
    setMarketDetail(undefined)
    setMarketModalOpen(false)
  }

  return (
    <div className="page-common page-avatar-market">
      <div className="common-title">
        <div className="text">数字市场</div>
      </div>
      <div className="nav">
        {statusList.map((s) => (
          <div className={`nav-item ${s.id === type ? 'actived' : ''}`} key={s.id} onClick={() => setType(s.id)}>
            <label>{s.title}</label>
          </div>
        ))}
      </div>
      <div className="page-container">
        {showData.map((d) => (
          <div className="list-image-box" key={`${d.id}${d.name}`} onClick={onMarketClick.bind(this, d)}>
            <div className="box-main">
              <div className="image">
                <img src={d.covers?.[0]} />
              </div>
              {d.special_cost && (
                <Popover
                  content={
                    <div className="common-popover w400" onClick={(e) => e.stopPropagation()}>
                      <div>
                        <strong>创作优惠：</strong>
                      </div>
                      <ul>
                        <li>
                          标有“创作优惠”的数字演员。雇佣后，在创作视频时享受积分消耗优惠（{d.creation_cost_per_second}{' '}
                          积分/秒），即：
                          <div className="ml10">a. 使用音频驱动生成视频时：{d.creation_cost_per_second} 积分/秒；</div>
                          <div className="ml10">
                            b. 使用公版声音、声音克隆-基础版生成视频时：{d.creation_cost_per_second + 1} 积分/秒；
                          </div>
                          <div className="ml10">
                            c. 使用声音克隆-高保真生成视频时：{d.creation_cost_per_second + 2} 积分/秒；
                          </div>
                        </li>
                        <li>仅从数字市场雇佣才有“创作优惠”标记，其他方式上传的视频均无法享有；</li>
                      </ul>
                    </div>
                  }
                  placement="bottom"
                >
                  <div className="discount" onClick={(e) => e.stopPropagation()}>
                    创作优惠
                    <ExclamationCircleOutlined />
                  </div>
                </Popover>
              )}

              {d.type === 1 ? (
                <>
                  {d.status === 1 && <div className="tag unstart">即将开始</div>}
                  {d.status === 2 && <div className="tag started">竞拍中</div>}
                  {d.status === 3 && <div className="tag end">已被雇佣</div>}
                </>
              ) : (
                <>
                  {d.status === 1 && <div className="tag started">一口价</div>}
                  {d.status === 2 && <div className="tag end">已被雇佣</div>}
                </>
              )}
            </div>
            <div className="bottom">
              <div className="desc">
                <div className="title">{d.name}</div>
                <div className="scene">{d.description}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <MarketModal open={marketModalOpen} marketDetail={marketDetail} onCancel={onCancel} />
    </div>
  )
}

export default memo(AvatarMarket)
