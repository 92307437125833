import './style.scss'
import { Layout } from 'antd'
import { FC, memo } from 'react'
import { Outlet } from 'react-router-dom'
import LayoutHeader from './components/header'
import LayoutMenu from './components/menu'
import Coupon from '../Coupon'

const LayoutIndex: FC = () => {
  return (
    <div className="layout-index">
      <LayoutHeader />
      <div className="layout-container">
        <LayoutMenu></LayoutMenu>
        <Layout.Content>
          <Outlet></Outlet>
        </Layout.Content>
      </div>
      <Coupon />
    </div>
  )
}

export default memo(LayoutIndex)
