export const Like = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      version="1.1"
      width="19.1299991607666"
      height="18.769603729248047"
      {...props}
    >
      <g>
        <path
          d="M16.9363,5.75627L11.2822,5.75627C11.5507,4.78731,11.6866,3.92625,11.6866,3.16818C11.6866,1.44815,10.1536,-0.199009,8.68608,0.0195849C7.39212,0.212256,6.77451,1.33115,6.77451,2.76812L6.77451,4.06637C6.77451,5.73175,5.53179,7.15191,3.95157,7.30814L1.43811,7.30394C0.643865,7.30394,0,7.96424,0,8.77875L0,17.2948C0,18.1096,0.643565,18.7696,1.43811,18.7696L15.3622,18.7696C16.4159,18.7697,17.3207,18.0012,17.517,16.9396L19.0911,8.42353C19.3467,7.03837,18.311,5.75627,16.9363,5.75627ZM1.36638,17.2948L1.36638,8.77875C1.36638,8.73811,1.39849,8.70518,1.43811,8.70518L3.07435,8.70518L3.07435,17.3684L1.43811,17.3684C1.3985,17.3684,1.36638,17.3354,1.36638,17.2948ZM17.7486,8.1629L16.1745,16.679C16.1005,17.0789,15.7594,17.3684,15.3622,17.3684L4.44073,17.3684L4.44073,8.64353C6.58437,8.22386,8.13639,6.3037,8.14089,4.06567L8.14089,2.76812C8.14089,1.94559,8.39845,1.47828,8.88215,1.40681C9.45945,1.32064,10.3203,2.24616,10.3203,3.16888C10.3203,3.9858,10.1174,5.00801,9.70403,6.22779C9.54988,6.6831,9.87966,7.15837,10.3496,7.15821L16.9363,7.15821C17.4541,7.15825,17.8445,7.641,17.7486,8.1629Z"
          fill="#3D3D3D"
          fillOpacity="1"
        />
      </g>
    </svg>
  )
}
