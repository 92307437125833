import './style.scss'
import { Modal } from 'antd'
import dayjs from 'dayjs'
import { FC, useEffect, useMemo, useState } from 'react'
import * as Api from '@/api/home'
import IconExpried from '@/assets/icon-expried.png'
import IconUsed from '@/assets/icon-used.png'

interface IProps {
  open: boolean
  toUseCoupon: () => void
  onCancel: () => void
}
const CouponListModal: FC<IProps> = (props) => {
  const { open, onCancel, toUseCoupon } = props
  const [coupons, setCoupons] = useState<any[]>()
  const [status, setStatus] = useState(-1)
  const tabs = [
    {
      id: -1,
      name: '全部'
    },
    {
      id: 2,
      name: '可使用'
    },
    {
      id: 3,
      name: '已使用'
    },
    {
      id: 4,
      name: '已过期'
    }
  ]

  const showCoupons = useMemo(() => {
    if (status === -1) {
      return coupons
    }
    return coupons?.filter((c) => c.status === status)
  }, [coupons, status])

  useEffect(() => {
    open && getClaimedCoupons()
  }, [open])

  const getClaimedCoupons = async () => {
    const res = await Api.getClaimedCoupons()
    setCoupons(res.list || [])
  }

  const changeStatus = (status: number) => {
    setStatus(status)
  }

  return (
    <>
      <Modal
        maskClosable={true}
        className="coupon-list-modal"
        onCancel={() => onCancel()}
        footer={null}
        width={700}
        open={open}
        title="我的优惠券"
      >
        <div className="coupon-tabs">
          {tabs.map((t) => (
            <div
              key={t.id}
              className={`coupon-tabs-item ${status === t.id ? 'actived' : ''}`}
              onClick={changeStatus.bind(this, t.id)}
            >
              {t.name}
            </div>
          ))}
        </div>
        {showCoupons && (
          <div className="coupon-list">
            {showCoupons?.length ? (
              <>
                {showCoupons.map((c) => (
                  <div key={c.id} className="coupon-list-item">
                    <div className="left">
                      <label>{c.deduct_amount / 100}</label>
                      <label className="unit">元</label>
                    </div>
                    <div className="right">
                      <div className="detail">
                        <div className="desc">{c.description}</div>
                        {c.expire_time && (
                          <div className="expried">
                            有效期至 {dayjs(c.expire_time * 1000).format('YYYY-MM-DD HH:mm')}
                            {}
                          </div>
                        )}
                        <div className="rules">
                          <div>使用规则</div>
                          <div>1. 本券用于新用户首次购买会员时立减 {c.deduct_amount / 100} 元；</div>
                          <div>2. 本券在购买会员时自动生效； </div>
                          <div>3. 本券自领取时间起24小时有效。</div>
                        </div>
                      </div>
                      <div
                        className={`btn ${c.status !== 2 ? 'disabled' : ''}`}
                        onClick={() => {
                          onCancel()
                          toUseCoupon()
                        }}
                      >
                        去使用
                      </div>
                    </div>
                    {c.status === 3 && <img className="tag" src={IconUsed} />}
                    {c.status === 4 && <img className="tag" src={IconExpried} />}
                  </div>
                ))}
              </>
            ) : (
              <div className="list-no-data">
                当前暂无{status !== -1 && tabs.find((t) => t.id === status)?.name}优惠券
              </div>
            )}
          </div>
        )}
      </Modal>
    </>
  )
}

export default CouponListModal
