import './style.scss'
import { Modal } from 'antd'
import { FC, useEffect, useState } from 'react'
import * as Api from '@/api/home'
import IconCreation from '@/assets/icon-creation.png'
import { Play } from '@/assets/svg'
import { eventTracking, urlSource } from '@/libs/util'
import VideoModal from '../VideoModal'

const CreationShare: FC = () => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState<any[]>([])
  const [preview, setPreview] = useState<any>()

  useEffect(() => {
    open && getData()
  }, [open])

  const getData = async () => {
    const res = await Api.getAdBoardList({
      position: 'CreationShare'
    })
    setData(res.list || [])
  }

  const showInspirationCreation = () => {
    eventTracking('CreationShare')
    setOpen(true)
  }

  return (
    <div className="inspiration-creation">
      <img className="img" src={IconCreation} onClick={showInspirationCreation} />
      <Modal
        title="案例分享"
        open={open}
        width="1000px"
        footer={null}
        onCancel={() => {
          setOpen(false)
        }}
        className="commom-modal inspiration-creation-modal"
      >
        <div className="wrapper">
          <div className="list">
            {data.map((d) => (
              <div className="list-item" key={d.display_url}>
                <div
                  className="box"
                  onClick={() => {
                    eventTracking('CreationShareClick')
                    setPreview({
                      url: d.display_url,
                      title: d.description
                    })
                  }}
                >
                  <div className="img">
                    <img src={urlSource(d.display_url, 'video')} />
                  </div>
                  <div
                    className="bg"
                    style={{
                      backgroundImage: `url(${urlSource(d.display_url, 'video')})`
                    }}
                  ></div>
                  <div className="play">
                    <Play />
                  </div>
                </div>
                <div className="desc">{d.description}</div>
              </div>
            ))}
          </div>
        </div>
      </Modal>

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} />
    </div>
  )
}

export default CreationShare
