import CryptoJS from 'crypto-js'
import { ReactElement, ReactNode, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { cmsApi } from '@/api'
import PlanModal from '@/components/PlanModal'
import { UserStore } from '@/global-states'

const env = import.meta.env
const videSuffix = '?x-oss-process=video/snapshot,t_0,m_fast,ar_auto'

export const urlSource = (url: string, type?: string) => {
  if (url?.indexOf('http') > -1 || url?.indexOf('//') > -1) {
    return type === 'video' ? `${url}${videSuffix}` : url
  }
  return `${env.VITE_API_BASE_URL_CDN}${url}${type === 'video' ? videSuffix : ''}`
}

export const tuple = <T extends any[]>(...elements: T) => {
  return elements
}

export const Render: (_: { children: () => ReactNode }) => ReactElement = (props: any) => <>{props.children()}</>

export const downlad = (urls: string, fileName: string) => {
  const x = new window.XMLHttpRequest()
  x.open('GET', urls, true)
  x.responseType = 'blob'

  x.onload = () => {
    const url = window.URL.createObjectURL(x.response)
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    a.download = fileName
    a.style.display = 'none'
    document.body.append(a)
    a.click()
    a.remove()
  }
  x.send()
  return x
}

export const findClosest = (arr: number[], target: number): number => {
  let closestIndex = 0
  let minDifference = Math.abs(arr[0] - target)

  for (let i = 1; i < arr.length; i++) {
    const difference = Math.abs(arr[i] - target)
    if (difference < minDifference) {
      minDifference = difference
      closestIndex = i
    }
  }

  return closestIndex
}

export const isMobile = () => {
  const userAgent: string = navigator.userAgent
  const mobileKeywords: string[] = ['Android', 'iPhone', 'iPad', 'Windows Phone', 'Mobile']
  const isMobile = mobileKeywords.some(function (keyword) {
    return userAgent.indexOf(keyword) !== -1
  })
  return isMobile
}

export const eventTracking = (event_name: string, properties = {}) => {
  if (!UserStore.getToken()) return
  const { uid, device_id } = UserStore
  const track = {
    event_name,
    di: device_id,
    client_time: parseInt((Date.now() / 1000).toFixed()),
    properties: JSON.stringify({
      page_route: location.href,
      uid: uid,
      platform: 'web',
      system: navigator.userAgent,
      ...properties
    })
  }
  cmsApi.post('event_logs', track)
}

export const Encrypt = (word: string) => {
  const key = CryptoJS.enc.Utf8.parse('GHJjPa9YBDaDZtzx')
  const iv = CryptoJS.enc.Utf8.parse('0')
  const encrypted = CryptoJS.AES.encrypt(word, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
}

export const showPlanModal = () => {
  const div = document.createElement('div')
  document.body.appendChild(div)

  ReactDOM.createRoot(div).render(
    <Render>
      {function showPlanModal() {
        const [open, setOpen] = useState(true)

        return <PlanModal open={open} onCancel={() => setOpen(false)} onSuccess={() => UserStore.updateUserPackage()} />
      }}
    </Render>
  )
}

export const demoVideos = [
  {
    url: 'hf/local/demo-videos/1.mp4',
    ossKey: 'public/hf/local/demo-videos/1.mp4'
  },
  {
    url: 'hf/local/demo-videos/2.mp4',
    ossKey: 'public/hf/local/demo-videos/2.mp4'
  },
  {
    url: 'hf/local/demo-videos/3.mp4',
    ossKey: 'public/hf/local/demo-videos/3.mp4'
  }
]

export const demoAudios = [
  {
    id: 1,
    name: '坚强隐忍',
    url: 'hf/local/demo-audios/1.wav',
    ossKey: 'public/hf/local/demo-audios/1.wav'
  },
  {
    id: 2,
    name: '安心做自己',
    url: 'hf/local/demo-audios/2.wav',
    ossKey: 'public/hf/local/demo-audios/2.wav'
  },
  {
    id: 3,
    name: '给自己一点时间',
    url: 'hf/local/demo-audios/3.wav',
    ossKey: 'public/hf/local/demo-audios/3.wav'
  }
]
