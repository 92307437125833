import './style.scss'
import { Button, Carousel, message, Table } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { cdnApi } from '@/api'
import * as Api from '@/api/account'
import IconScore from '@/assets/icon-score.png'
import IconShareActive from '@/assets/icon-share-active.png'
import IconShare from '@/assets/icon-share.png'
import IconUserplusActive from '@/assets/icon-userplus-active.png'
import IconUserplus from '@/assets/icon-userplus.png'
import IconUsersActive from '@/assets/icon-users-active.png'
import IconUsers from '@/assets/icon-users.png'
import Douban from '@/assets/plat-douban.png'
import Douyin from '@/assets/plat-douyin.png'
import Kuaishou from '@/assets/plat-kuaishou.png'
import Shipinhao from '@/assets/plat-shipinhao.png'
import Weibo from '@/assets/plat-weibo.webp'
import Xiaohongshu from '@/assets/plat-xiaohongshu.png'
import Zhihu from '@/assets/plat-zhihu.png'
import QrcodeMini from '@/assets/qrcode-mini.png'
import { eventTracking, urlSource } from '@/libs/util'

const Invite: FC = () => {
  const [profile, setProfile] = useState<any>()
  const [logs, setLogs] = useState<any>([])
  const [config, setConfig] = useState<any>()
  const [tabIndex, setTabIndex] = useState(0)
  const [recommendTabIndex, setRecommendTabIndex] = useState(0)
  const tabRef1 = useRef<any>()
  const tabRef2 = useRef<any>()
  const tabRef3 = useRef<any>()

  useEffect(() => {
    eventTracking('MyInvitePage')
    getProfile()
    getCreditsLogs()
    // getActivity()
  }, [])

  const getProfile = async () => {
    const res = await Api.getProfile()
    setProfile(res)
  }

  const getCreditsLogs = async () => {
    const res = await Api.getCreditsLogs()
    setLogs(res.list || [])
  }

  const getActivity = async () => {
    const res = await cdnApi.get(`hiflys/point-activity/config.json??date=${Date.now()}`)
    setConfig(res || [])
  }

  const tabIndexChange = (index: number) => {
    setTabIndex(index)
    if (index === 0) {
      tabRef1.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (index === 1) {
      tabRef2.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (index === 2) {
      tabRef3.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const submit = () => {
    window.open('https://lingverse.feishu.cn/share/base/form/shrcntjzUCVYHIq7QX3sNPJ086b')
  }

  return (
    <div className="page-invite">
      <div className="page-invite-wrapper">
        <div className="page-invite-main">
          {!!config?.length && (
            <div className="carousel">
              <Carousel autoplay>
                {config?.map((c: any) => (
                  <div className="carousel-banner" key={c.url}>
                    <img src={urlSource(c.url)} />
                  </div>
                ))}
              </Carousel>
            </div>
          )}

          <div className="invite-header">
            <div className="invite-title">
              <img className="icon" src={IconScore} />
              <label>
                免费获取积分<span className="border"></span>
              </label>
            </div>

            <div className="invite-info">
              <div className="info-item">
                <div className="text">已获得赠送积分</div>
                <div className="num">{profile?.total_gained_credits || 0}</div>
              </div>
              <div className="info-item">
                <div className="text">已邀请成单次数</div>
                <div className="num">{profile?.affiliated_membership_order_count || 0}</div>
              </div>
              <div className="info-item">
                <div className="text">已邀请注册用户</div>
                <div className="num">{profile?.affiliated_new_user_count || 0}</div>
              </div>
            </div>

            <div></div>

            <div className="tabs">
              <div className={`tab-item ${tabIndex === 0 ? 'actived' : ''}`} onClick={tabIndexChange.bind(this, 0)}>
                <img src={tabIndex === 0 ? IconUsersActive : IconUsers} />
                <span className="text">邀请好友下单</span>
              </div>
              <div className={`tab-item ${tabIndex === 1 ? 'actived' : ''}`} onClick={tabIndexChange.bind(this, 1)}>
                <img src={tabIndex === 1 ? IconUserplusActive : IconUserplus} />
                <span className="text">邀请好友注册</span>
              </div>
              <div className={`tab-item ${tabIndex === 2 ? 'actived' : ''}`} onClick={tabIndexChange.bind(this, 2)}>
                <img src={tabIndex === 2 ? IconShareActive : IconShare} />
                <span className="text">种草推荐</span>
              </div>
            </div>
          </div>

          <div className="invite-main" ref={tabRef1}>
            <div className="content">
              <div className="left">
                <div className="content-title">邀请好友下单，好友成功支付后，你和好友都可获得积分，多邀多得！</div>
                <div className="content-desc l28">
                  好友在购买单月会员时填写你的邀请码，成功支付后，则双方均可获得<label className="num">3000</label>
                  积分奖励
                </div>
                <div className="content-desc l28">
                  好友在购买年度会员时填写你的邀请码，成功支付后，则双方均可获得<label className="num">36000</label>
                  积分奖励
                </div>
                <div className="form-item">
                  <div className="form-content">
                    <div className="link">
                      <div className="h">{`${location.origin}/setting?code=${profile?.affiliate_code}`}</div>
                    </div>
                    <CopyToClipboard
                      text={`${location.origin}/setting?code=${profile?.affiliate_code}`}
                      onCopy={() => {
                        message.success('已复制邀请链接')
                      }}
                    >
                      <Button type="primary">复制邀请链接</Button>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="form-item">
                  <div className="form-content">
                    <div className="link">
                      <div className="h">{profile?.affiliate_code}</div>
                    </div>
                    <CopyToClipboard
                      text={profile?.affiliate_code}
                      onCopy={() => {
                        message.success('已复制邀请码')
                      }}
                    >
                      <Button type="primary">复制邀请码</Button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="invite-main" ref={tabRef2}>
            <div className="content">
              <>
                <div className="left">
                  <div className="content-title">
                    邀请好友注册，好友成功注册登录后，你和好友都可获得积分，多邀多得！
                  </div>
                  <div className="content-desc">
                    新用户使用你的小程序邀请链接，注册并成功登录“飞影数字人”小程序，
                    <br />
                    则双方均可获得
                    <label className="num">100</label>积分奖励
                  </div>

                  <div className="content-title mt36">邀请链接获取方式</div>
                  <div className="content-desc">
                    微信进入“飞影数字人”小程序，登录后点击“我的 - 免费获取积分”即可看到
                  </div>
                </div>
                <div className="right">
                  <img className="img" src={QrcodeMini} />
                  <div className="tip">微信扫一扫，进入小程序</div>
                </div>
              </>
            </div>
          </div>

          <div className="invite-main" ref={tabRef3}>
            <div className="content">
              <div className="recommend">
                <div className="content-title">发布种草推荐到以下任一平台，最高获得5000积分</div>
                <div className="recommend-wrapper-content-tab">
                  <div
                    className={`tab-item ${recommendTabIndex === 0 ? 'active' : ''}`}
                    onClick={() => setRecommendTabIndex(0)}
                  >
                    <div className="tab-item-in">
                      <div className="tab-item-in-cont">
                        <span className="num">1</span>
                        <span data-v-51648ee7="">种草推荐</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-item ${recommendTabIndex === 1 ? 'active' : ''}`}
                    onClick={() => setRecommendTabIndex(1)}
                  >
                    <div className="tab-item-in">
                      <div className="tab-item-in-cont">
                        <span className="num">2</span>
                        <span>提交验证</span>
                      </div>
                    </div>
                  </div>
                </div>
                {recommendTabIndex === 0 && (
                  <div className="recommend-content">
                    <div className="recommend-content-tip">
                      在以下任一平台上发布你使用飞影数字人的真实感受，请确保文案中含有「飞影数字人 」关键词：
                    </div>
                    <div className="platform">
                      <div className="platform-item">
                        <a
                          target="_blank"
                          href="https://creator.douyin.com/creator-micro/content/upload?enter_from=dou_web"
                          rel="noreferrer"
                        >
                          <img src={Douyin} />
                        </a>
                        <div className="text">抖音</div>
                      </div>
                      <div className="platform-item">
                        <a
                          target="_blank"
                          href="https://creator.xiaohongshu.com/publish/publish?source=official"
                          rel="noreferrer"
                        >
                          <img src={Xiaohongshu} />
                        </a>
                        <div className="text">小红书</div>
                      </div>
                      <div className="platform-item">
                        <a
                          target="_blank"
                          href="https://cp.kuaishou.com/article/publish/video?origin=www.kuaishou.com"
                          rel="noreferrer"
                        >
                          <img src={Kuaishou} />
                        </a>
                        <div className="text">快手</div>
                      </div>
                      <div className="platform-item">
                        <a target="_blank" href="https://channels.weixin.qq.com/platform/post/create" rel="noreferrer">
                          <img src={Shipinhao} />
                        </a>
                        <div className="text">视频号</div>
                      </div>
                      <div className="platform-item">
                        <a target="_blank" href="https://www.douban.com/" rel="noreferrer">
                          <img src={Douban} />
                        </a>
                        <div className="text">豆瓣</div>
                      </div>
                      <div className="platform-item">
                        <a target="_blank" href="https://www.zhihu.com/creator" rel="noreferrer">
                          <img src={Zhihu} />
                        </a>
                        <div className="text">知乎</div>
                      </div>
                      <div className="platform-item">
                        <a target="_blank" href="https://weibo.com/" rel="noreferrer">
                          <img src={Weibo} />
                        </a>
                        <div className="text">微博</div>
                      </div>
                    </div>
                  </div>
                )}
                {recommendTabIndex === 1 && (
                  <div className="recommend-content">
                    <div className="recommend-content-tip">
                      请上传您的发帖或视频截图，并输入对应链接，我们将在验证后为您发放积分。
                    </div>
                    <div className="recommend-content-tip">请确保你提交的信息的真实性，否则可能无法通过审批。</div>
                    <div className="recommend-bottom">
                      <div className="recommend-content-tip">验证通常需要1-2个工作日，请耐心等待。</div>
                      <Button type="primary" onClick={submit}>
                        开始提交
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="invite-tips">
            <div className="title">活动规则</div>
            <ul>
              <li>每条发帖奖励 500 积分；</li>
              <li>同一账号重复评论同一平台视为无效</li>
            </ul>
          </div>

          <div className="invite-tips">
            <div className="title">活动申明</div>
            <ul>
              <li>本活动严禁不真实数据作弊行为，一经核实，取消奖励；</li>
            </ul>
          </div>

          <div className="invite-table">
            <div className="table-title">奖励发放记录</div>
            <Table
              pagination={
                logs?.length > 8
                  ? {
                      pageSize: 8,
                      showSizeChanger: false
                    }
                  : false
              }
              locale={{
                emptyText: <div style={{ padding: '40px 0' }}>暂无记录</div>
              }}
              columns={[
                {
                  title: '类型',
                  dataIndex: 'title'
                },
                {
                  title: '发放时间',
                  dataIndex: 'create_time',
                  render: (time: number) => <div>{dayjs(time * 1000).format('YYYY-MM-DD HH:mm')}</div>
                },
                {
                  title: '积分',
                  dataIndex: 'credits'
                }
              ]}
              dataSource={logs}
            ></Table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Invite)
