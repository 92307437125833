import './style.scss'
import { Table } from 'antd'
import dayjs from 'dayjs'
import { FC, memo } from 'react'

interface IProps {
  list: any[]
}

const PointDetail: FC<IProps> = (props) => {
  const { list } = props

  return (
    <div className="setting-table">
      <div className="title">积分收支明细</div>
      <Table
        pagination={
          list?.length > 8
            ? {
                pageSize: 8,
                showSizeChanger: false
              }
            : false
        }
        columns={[
          {
            title: '类型',
            dataIndex: 'description',
            width: 300
          },
          {
            title: '积分变动数量',
            dataIndex: 'credits_delta',
            render: (num: number) => <div>{num > 0 ? `+${num}` : num}</div>,
            width: 300
          },
          {
            title: '时间',
            dataIndex: 'create_time',
            width: 300,
            render: (time: number) => <div>{dayjs(time * 1000).format('YYYY-MM-DD HH:mm')}</div>
          }
        ]}
        locale={{
          emptyText: <div style={{ padding: '40px 0' }}>暂无记录</div>
        }}
        dataSource={list}
      ></Table>
    </div>
  )
}

export default memo(PointDetail)
